// Import Modules
import {
  registerServiceWorker
} from './registerServiceWorker';

import $ from 'jquery';
import 'slick-carousel';

var mapUrl = "https://www.google.co.uk/maps/place/150+Belgrave+Rd,+Leicester+LE4+6LF/@52.6490491,-1.1254689,17z/data=!3m1!4b1!4m5!3m4!1s0x4877610f4c21a18d:0xee866fa1b2404c3c!8m2!3d52.6490491!4d-1.1232802"

// Mobile Menu
$('.hamburger').click(function () {
  $('.navbar-items, .navbar').toggleClass('display');
  $('.hamburger').toggleClass('active');
  $('body').toggleClass('fixed');
});

$('.navbar-items > a').click(function (){
  $('.hamburger').click();
})

$('.dropdown').click(function () {
  $('.dropdown-content').toggleClass('toggle');
});

$('.maplink').click(function() {
  window.open(mapUrl,'_blank');
});

$('#mail-btn').click(function() {
  sendMail();
});

function offsetAnchor() {
  if(location.hash.length !== 0) {
      window.scrollTo(window.scrollX, window.scrollY - 100);
  }
}

window.addEventListener("hashchange", offsetAnchor);
window.setTimeout(offsetAnchor, 1);

// Sliders
$('.slider').slick({
  centerPadding: '60px',
  slidesToShow: 1,
  autoplay: true
});

$('.slider-three').slick({
  slidesToShow: 3,
  centerMode: true,
  autoplay: true,
  responsive: [{
      breakpoint: 768,
      settings: {
        arrows: true,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 3
      }
    },
    {
      breakpoint: 480,
      settings: {
        arrows: true,
        centerMode: false,
        centerPadding: '40px',
        slidesToShow: 1
      }
    }
  ]
})

// Navbar
$(function () {
  $(document).scroll(function () {
    var $nav = $("nav");
    $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
  });
});

// Href Scrolling
$(document).on('click', 'a[href^="#"]', function (event) {
  event.preventDefault();

  $('html, body').animate({
    scrollTop: $($.attr(this, 'href')).offset().top - 120
  }, 700);
});

// Scroll Up Button

// Load Menu

var pathname = window.location.pathname;
pathname = pathname.match(/([^\/]*)\/*$/)[1]

switch (pathname) {
  case "starters.html":
    getMenu("Starters");
    break;
  case "mains.html":
    getMenu("Main Course");
    break;
  case "thali.html":
    getMenu("Thali");
    break;
  case "desserts.html":
    getMenu("Desserts");
    break;
  case "drinks.html":
    getMenu("Cold Drinks");
    break;
}

function getMenu(page) {
  var apiEntry = "https://www.dakshin.co.uk/api/";
  var apiKey = "token=account-dce6109ef910be16a88c2074a7da01";

  $.getJSON(apiEntry + "api/collections/get/Menu/?" + apiKey, function (data) {
    $('#menu-container').hide();
    $('.loading').hide();
    $('#menu-container').fadeIn();
    var filter = filterByType(data.entries, page);
    var container = document.getElementById('menu-container');
  
    $.each(filter, function (key, val) {
      var header = '<section class="feature-menu"><div class="container"><h1>' + val.title + '</h1><hr><h2>' + val.mealType + '</h2></div></section>';
      $(container).append(header)
      var headerImage = $('<section>', {
        class: 'fullwidth-image background-cover'
      });
      
      headerImage.css('background-image', 'url(' + val.image.path + ')');
      
      
      $(container).append(headerImage);

      var menuContainer = $('<section>', {
        class: 'menu-items'
      });
      var c = $('<div>', {
        class: 'container'
      });
      menuContainer.append(c);

      $.each(this.meal, function (key, val) {
        var item = this.value;
        if(item !== null){
        var content = '<li><div class="item"><h3>' + item.name + '</h3><p>' + item.description + '</p></div><div class="item-price"><span>' + item.price + '</span></div></li>';
        c.append(content);
      }
      });

      $(container).append(menuContainer);
    });
  });
}

function sendMail(){
  $('.loading').show();

  // Make API Request
  var data = {
    form_name: $("#form_name").val(),
    form_email: $("#form_email").val(),
    form_subject: $("#form_subject").val(),
    form_phone: $("#form_phone").val(),
    form_message: $("#form_message").val(),
  }
  $.ajax({
    type: "POST",
    url: "https://dakshin.co.uk/mail-send/form.php",
    data: data,
    error: function(data){
      $('.loading').hide();
      console.log("error");
    },
    success: function(data){
      $('.loading').hide();
      var result = JSON.parse(data);
      if(result.error == true){
        $('#form-messages').text(result.message);
      } else {
        $('#form-messages').text(result.message);
        $('.form-mail').slideUp();
      }
    }
  })


}

function filterByType(menuArray, type) {
  const result = menuArray.filter(menuArray => menuArray.menuType == type);
  return result;
}

// Register Service Worker
registerServiceWorker();
